import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Chip, FormControl, FormGroup, FormLabel, MenuItem, TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import Container from '../../../../components/Container';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import SelectInput from '../../../../components/forms/SelectInput';
import { DMSortType, SortDirection } from '../../../../declarations/models/SortOption';
import SwitchInput from '../../../../components/forms/SwitchInput';
import { settingsAreaStyle } from '../EmployeeBlocks/EmployeesAutoBlock';
import { DMObjectType } from '../../../../declarations/models/DMObjectType';
import { MediaLicense } from '../../../../declarations/models/MediaLicense';
import { DMAutoBlockModel, DMViewType } from '../../../../declarations/models/blocks/DMBlock';
import { DimuOwner } from '../../../../declarations/models/DimuOwner';
import { Api } from '../../../../services/Api';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import TextInput from '../../../../components/forms/TextInput';

const autocompleteItemStyle = (selected: boolean) => ({
  backgroundColor: selected ? 'rgba(0,0,0,0.16) !important' : undefined,
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.08) !important',
  },
});

const getOptionLabel = (option: DimuOwner) => `${option.name} (${option.identifier})`;

export const getMapSettings = (t: any, blockPath: BlockPath) => (
  <FormControl sx={settingsAreaStyle}>
    <FormLabel>{t('DMBlock.MapSettings')}</FormLabel>
    <FormGroup row>TODO: Add Map settings</FormGroup>
  </FormControl>
);

export const DMAutoBlock: BlockComponent = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComp } = useTranslation('components');
  const { blockPath, block } = useCurrentBlock();
  const currentBlock = block as DMAutoBlockModel;
  const [allDimuOwners, setAllDimuOwners] = useState<Array<DimuOwner>>([]);
  const [selectedOwners, setSelectedOwners] = useState<Array<DimuOwner>>([]);

  const gridSizeOptions = ['auto', '3', '4', '5', '6'];

  const {
    field: { value: ownerCodes, onChange: setOwnerCodes },
  } = useController<BlockSpecificPage<DMAutoBlockModel>, `${typeof blockPath}.ownerCodes`>({
    name: `${blockPath}.ownerCodes`,
  });

  const {
    field: { value: maxItems, onChange: setMaxItems },
  } = useController<BlockSpecificPage<DMAutoBlockModel>, `${typeof blockPath}.maxItems`>({
    name: `${blockPath}.maxItems`,
  });

  const {
    field: { value: paginateMaxItems, onChange: setPaginateMaxItems },
  } = useController<BlockSpecificPage<DMAutoBlockModel>, `${typeof blockPath}.paginateMaxItems`>({
    name: `${blockPath}.paginateMaxItems`,
  });

  useEffect(() => {
    const ctx = Api.getDimuOwners();
    const ctx2 = Api.getDimuOwners('sv');

    Promise.all([ctx.fetchDirect(null), ctx2.fetchDirect(null)])
      .then(([res1, res2]) => {
        const allOwners = [...(res1?.owners || []), ...(res2?.owners || [])];
        setAllDimuOwners(allOwners);
        if (ownerCodes) {
          const ownerList = ownerCodes.split(',');
          setSelectedOwners(allOwners.filter((o) => ownerList.some((oc) => oc === o.identifier)));
        }
      })
      .finally(() => {
        ctx.abort();
        ctx2.abort();
      });
  }, [ownerCodes]);

  const handleOwnerCodesChanged = (owners: Array<DimuOwner>) => {
    setSelectedOwners(owners);
    setOwnerCodes(owners.map((o) => o.identifier).join(','));
  };

  const getSortDirectionLabel = (dir: SortDirection) => {
    if (currentBlock?.sortBy === DMSortType.PUBLISHED_AT || currentBlock?.sortBy === DMSortType.UPDATED_AT) {
      return tCommon(`SortDirections.date_${dir}`);
    }
    if (currentBlock?.sortBy === DMSortType.TITLE) return tCommon(`SortDirections.text_${dir}`);
    return tCommon(`SortDirections.${dir}`);
  };

  return (
    <Container gap={2} column top left fullWidth>
      <FormControl sx={settingsAreaStyle}>
        <Container>
          <SelectInput
            options={Object.values(DMObjectType)}
            getOptionKey={(o) => o}
            getOptionLabel={(o) => tCommon(`DMContentType.${o}`)}
            path={`${blockPath}.objectTypes`}
            label={tComp('DMBlock.ObjectTypesLabel')}
            size='small'
            multiSelect
            initialMultiSelectValue={currentBlock?.objectTypes}
          />
          <SelectInput
            options={Object.values(MediaLicense).filter((l) => !!l)}
            getOptionKey={(o) => o}
            getOptionLabel={(o) => tCommon(`MediaLicense.Label.${o}`)}
            path={`${blockPath}.licenseTypes`}
            renderValue={(v) => (typeof v === 'string' ? v : v.join(','))}
            label={tComp('DMBlock.LicenseTypesLabel')}
            size='small'
            multiSelect
            initialMultiSelectValue={currentBlock?.licenseTypes}
          />
        </Container>
        <TextInput path={`${blockPath}.query`} defaultValue='' label={tComp('DMBlock.QueryLabel')} size='small' />
        <TextInput path={`${blockPath}.subjects`} defaultValue='' label={tComp('DMBlock.SubjectsLabel')} size='small' />
        <div style={{ alignSelf: 'flex-start' }}>
          <SwitchInput path={`${blockPath}.hasPictures`} label={tComp('DMBlock.HasPictures')} />
        </div>
        <div style={{ alignSelf: 'flex-start' }}>
          <SwitchInput path={`${blockPath}.useOwnerCodes`} label={tComp('DMBlock.UseOwnerCodes')} />
        </div>
        {currentBlock?.useOwnerCodes && !!allDimuOwners?.length && (
          <Autocomplete
            multiple
            options={allDimuOwners}
            getOptionLabel={getOptionLabel}
            value={selectedOwners}
            size='small'
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} sx={autocompleteItemStyle(selected)}>
                {getOptionLabel(option)}
              </MenuItem>
            )}
            fullWidth
            disableCloseOnSelect
            isOptionEqualToValue={(o, v) => o.identifier === v.identifier}
            renderInput={(params) => <TextField {...params} label={tComp('DMBlock.OwnerCodesLabel')} />}
            onChange={(e, v) => handleOwnerCodesChanged(v)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size='small' {...getTagProps({ index })} key={option.identifier} label={option.identifier} />
              ))
            }
          />
        )}
      </FormControl>
      <FormControl sx={settingsAreaStyle}>
        <Container>
          <SelectInput
            options={Object.values(DMSortType)}
            getOptionKey={(o) => o}
            getOptionLabel={(o) => tCommon(`DMSortTypes.${o}`)}
            path={`${blockPath}.sortBy`}
            label={tCommon('SortTypeLabel')}
            defaultValue={DMSortType.RELEVANCE}
            size='small'
            hideNoSelectionOption
          />
          <SelectInput
            options={Object.values(SortDirection)}
            getOptionKey={(o) => o}
            getOptionLabel={getSortDirectionLabel}
            path={`${blockPath}.order`}
            disabled={!currentBlock?.sortBy || currentBlock?.sortBy === DMSortType.RELEVANCE}
            label={tCommon('SortDirectionLabel')}
            defaultValue={currentBlock?.sortBy === DMSortType.TITLE ? SortDirection.ASC : SortDirection.DESC}
            size='small'
            hideNoSelectionOption
          />
        </Container>
        <TextField
          type='number'
          size='small'
          value={maxItems ?? 0}
          onChange={(v) => setMaxItems(Number(v.target.value) || undefined)}
          fullWidth
          label={tComp('DMBlock.MaxItemsLabel')}
          inputProps={{
            min: 0,
            max: 24,
          }}
          InputLabelProps={{
            shrink: !!Number(maxItems),
          }}
        />

        <TextField
          type='number'
          size='small'
          value={paginateMaxItems ?? 0}
          onChange={(v) => setPaginateMaxItems(Number(v.target.value) || undefined)}
          fullWidth
          label={tComp('DMBlock.PaginateMaxItemsLabel')}
          inputProps={{
            min: 0,
            max: 1000,
          }}
          InputLabelProps={{
            shrink: !!Number(paginateMaxItems),
          }}
        />

        <TextInput path={`${blockPath}.dmLink`} defaultValue='' label={tComp('DMBlock.DmLink')} size='small' />
        <TextInput path={`${blockPath}.dmLinkText`} defaultValue='' label={tComp('DMBlock.DmLinkText')} size='small' />

        <SelectInput
          hideNoSelectionOption
          defaultValue='auto'
          options={gridSizeOptions}
          getOptionKey={(gridSize) => `size-${gridSize}`}
          getOptionLabel={(gridSize) => `${gridSize}`}
          path={`${blockPath}.gridSize`}
          label={tComp('CardModuleBlock.GridSizeLabel')}
        />
      </FormControl>
      {currentBlock?.view === DMViewType.MAP && getMapSettings(tComp, blockPath)}
    </Container>
  );
};

export default DMAutoBlock;
