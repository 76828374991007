import { DatelineBlock } from '@/declarations/models/blocks/DatelineBlock';
import { ProgramBlockModel } from '@/declarations/models/blocks/ProgramBlock';
import { FormBlockModel } from '@/declarations/models/blocks/FormBlockModel';
import { MapDesign, MapType, MapV2Block } from '@/declarations/models/blocks/MapV2';
import { BlockType } from '../../../declarations/models/BlockType';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';
import { MediaViewType } from '../../../declarations/models/blocks/MediaViewType';
import { MediaBlock } from '../../../declarations/models/blocks/MediaBlock';
import { ArticleTitleBlock } from '../../../declarations/models/blocks/ArticleTitleBlock';
import { TextBlock } from '../../../declarations/models/blocks/TextBlock';
import { Alignment } from '../../../declarations/models/Alignment';
import {
  BannerBlock,
  BannerLayout,
  BannerSize,
  BannerTextLineColor,
  BannerTitleSize,
  BannerTitleTransformation,
  BannerTitleWeight,
} from '../../../declarations/models/blocks/BannerBlock';
import { VerticalAlignment } from '../../../declarations/models/VerticalAlignment';
import { CardModuleBlock, CardModuleView } from '../../../declarations/models/blocks/CardModuleBlock';
import { LinkListBlock } from '../../../declarations/models/blocks/LinkListBlock';
import {
  PageListAutoBlock,
  PageListLayout,
  PageListManualBlock,
  PageListViewType,
} from '../../../declarations/models/blocks/PageListBlocks';
import { LinkStyle } from '../../../declarations/models/LinkStyle';
import { AttachmentsBlock } from '../../../declarations/models/blocks/AttachmentsBlock';
import { TableBlock } from '../../../declarations/models/blocks/TableBlock';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';
import { EmployeesBlock, EmployeesViewType } from '../../../declarations/models/blocks/EmployeesBlock';
import {
  DMAutoBlockModel,
  DMManualBlockModel,
  DMSingleBlockModel,
  DMViewType,
} from '../../../declarations/models/blocks/DMBlock';
import { DMSortType, SortDirection, SortType } from '../../../declarations/models/SortOption';
import { BlockLayout } from '../../../declarations/models/BlockLayout';
import { KPBlockModel, KPOwnerBlockModel, KPViewType, KPLayout } from '../../../declarations/models/blocks/KPBlock';
import { ViewWidth } from '../../../declarations/ViewType';
import {
  EventsAutoBlock,
  EventsLayout,
  EventsManualBlock,
  EventsViewType,
} from '../../../declarations/models/blocks/EventsBlocks';

/**
 * A factory creating a block-model with all default values applied
 *
 * @example
 * ```
 * BlockFactory.makeBlockModel(BlockType.MEDIA)
 * ```
 */
export abstract class BlockModelFactory {
  private static getBaseBlock(): Omit<BaseBlock, 'type'> {
    // noinspection SpellCheckingInspection
    return {
      _moduleCreatedByAdmin2: true,
      visibletitle: false,
      visible: 'visible',
      _collapse: true,
    };
  }

  private static getArticleTitle(): ArticleTitleBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.ARTICLE_TITLE,
      align: {},
      textAlign: Alignment.LEFT,
      title: '',
      leadtext: '',
    };
  }

  private static getArticleText(): TextBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.TEXT,
      visibletitle: true,
      bodytext: '',
      layout: 'auto',
      items: [],
    };
  }

  private static getMedia(): MediaBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.MEDIA,
      animation: 'slide',
      format: 'auto',
      items: [],
      layout: 'auto',
      media__type: '',
      position: Alignment.NORMAL,
      skin: 'skin',
      slideshow: '',
      view: MediaViewType.LIST,
      visibletitle: true,
    };
  }

  private static getAttachments(): AttachmentsBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.ATTACHMENT,
      items: [],
      view: MediaViewType.LIST,
    };
  }

  private static getTable(): TableBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.TABLE,
      items: [],
      skin: 'skin',
      columns: 0,
      module__type: BlockModuleType.INLINE,
      visibletitle: true,
    };
  }

  private static getEmployeesAuto(): EmployeesBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.EMPLOYEES_AUTO,
      siteIds: '',
      showEmail: true,
      showPhone: true,
      showLinks: false,
      showSite: false,
      showDepartment: false,
      enableSearch: false,
      view: EmployeesViewType.GRID,
      skin: 'skin',
      visibletitle: true,
    };
  }

  private static getEmployeesManual(): EmployeesBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.EMPLOYEES_MANUAL,
      items: [],
      showEmail: true,
      showPhone: true,
      showLinks: false,
      showSite: false,
      showDepartment: true,
      enableSearch: false,
      view: EmployeesViewType.LIST,
      skin: 'skin',
      visibletitle: true,
    };
  }

  private static getDMAuto(): DMAutoBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.DM_AUTO,
      useOwnerCodes: false,
      view: DMViewType.GRID,
      width: ViewWidth.AUTO,
      maxItems: 12,
      sortBy: DMSortType.UPDATED_AT,
      visibletitle: true,
    };
  }

  private static getDMManual(): DMManualBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.DM_MANUAL,
      view: DMViewType.GRID,
      width: ViewWidth.AUTO,
      dmItems: [],
      vrItems: [],
      visibletitle: true,
    };
  }

  private static getDMSingle(): DMSingleBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      visibletitle: true,
      type: BlockType.DM_SINGLE,
      layout: BlockLayout.AUTO,
      selectedImgIndex: 0,
      skin: 'skin',
    };
  }

  private static getKP(): KPBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.KP,
      view: KPViewType.GRID,
      layout: KPLayout.IMAGE_TOP,
      width: ViewWidth.AUTO,
      articles: [],
      visibletitle: true,
      displayDescription: false,
    };
  }

  private static getKPOwner(): KPOwnerBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.KP_OWNER,
      width: ViewWidth.AUTO,
      layout: KPLayout.TEXT_ON_IMAGE,
      owners: [],
      presentations: [],
      visibletitle: true,
      displayDescription: true,
    };
  }

  private static getBanner(): BannerBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.FRONTPAGE,
      items: [],
      layout: BannerLayout.TEXT_ON_IMAGE,
      mediaitems: [],
      size: BannerSize.LARGE_MEDIUM,
      textValign: VerticalAlignment.DEFAULT,
      textAlign: Alignment.DEFAULT,
      textlineColor: BannerTextLineColor.DARK_SEMI_TRANSPARENT,
      titleSize: BannerTitleSize.DEFAULT,
      titleTransform: BannerTitleTransformation.NORMAL,
      titleWeight: BannerTitleWeight.DEFAULT,
      useModuleContentBackgound: false,
      useTextbackgound: false,
      linkstyle: LinkStyle.BUTTONS,
    };
  }

  private static getCardModule(): CardModuleBlock {
    return {
      version: '2',
      entity_subtype: '',
      skin: '',
      items: [],
      media__type: '',
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.CARD_SEARCH,
      view: CardModuleView.GRID,
      rows: '20',
      order: 'desc',
      orderBy: 'date',
      structure__search: 'page',
      settings: {
        metadataformat: '[FOOTERLOC] [FOOTERTEXT]',
        size: '',
        mediaRatio: '',
      },
    };
  }

  private static getLinkListBlock(): LinkListBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      items: [],
      type: BlockType.RELATIONS,
      view: 'list',
      orderBy: false,
      groupBy: false,
      showDate: false,
      visibletitle: true,
    };
  }

  private static getPageListAutoBlock(): PageListAutoBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      view: PageListViewType.GRID,
      type: BlockType.PAGE_LIST_AUTO,
      displayType: 'pageTree',
      layout: PageListLayout.IMAGE_TOP,
      searchLevel: 'children',
      subCategories: [],
      visibletitle: true,
      displayDescription: true,
      orderBy: SortType.PUBLISHED_AT,
      order: SortDirection.DESC,
    };
  }

  private static getPageListManualBlock(): PageListManualBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      view: PageListViewType.GRID,
      type: BlockType.PAGE_LIST_MANUAL,
      items: [],
      layout: PageListLayout.IMAGE_TOP,
      visibletitle: true,
      displayDescription: true,
    };
  }

  private static getEventsAutoBlock(): EventsAutoBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      view: EventsViewType.GRID,
      type: BlockType.EVENTS_AUTO,
      layout: EventsLayout.IMAGE_TOP,
      subCategories: [],
      visibletitle: true,
      displayLocation: true,
      displayDescription: true,
      showForPeriodType: '6m',
    };
  }

  private static getEventsManualBlock(): EventsManualBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      view: EventsViewType.GRID,
      type: BlockType.EVENTS_MANUAL,
      items: [],
      layout: EventsLayout.IMAGE_TOP,
      visibletitle: true,
      displayLocation: true,
      displayDescription: true,
    };
  }

  private static getDatelineBlock(): DatelineBlock {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.DATELINE,
      subtype: 'dateline',
    };
  }

  private static getFormBlock(): FormBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.FORM,
      skin: 'skin',
      module__type: BlockModuleType.MODULE,
      visibletitle: true,
    };
  }

  private static getProgramBlock(): ProgramBlockModel {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.PROGRAM,

      skin: 'skin',
      module__type: BlockModuleType.MODULE,
      visibletitle: true,
    };
  }

  private static getMapV2Block(): MapV2Block {
    return {
      ...BlockModelFactory.getBaseBlock(),
      type: BlockType.MAP_V2,
      mapDesign: MapDesign.NORMAL,
      markers: [],
      siteLocations: [],
      mapType: MapType.ROADMAP,
      enableStreetView: true,
      enableMapType: true,
      enableWayfinder: true,
      width: 'auto',
    };
  }

  /**
   * Creates a new Block-object of the specified type, with all default values applied.
   * @param blockType The block type to create
   */
  public static makeBlockModel(blockType: BlockType): BaseBlock {
    switch (blockType) {
      case BlockType.MEDIA:
        return BlockModelFactory.getMedia();
      case BlockType.TEXT:
        return BlockModelFactory.getArticleText();
      case BlockType.ATTACHMENT:
        return BlockModelFactory.getAttachments();
      case BlockType.ARTICLE_TITLE:
        return BlockModelFactory.getArticleTitle();
      case BlockType.FRONTPAGE:
        return BlockModelFactory.getBanner();
      case BlockType.CARD_SEARCH:
        return BlockModelFactory.getCardModule();
      case BlockType.RELATIONS:
        return BlockModelFactory.getLinkListBlock();
      case BlockType.PAGE_LIST_AUTO:
        return BlockModelFactory.getPageListAutoBlock();
      case BlockType.PAGE_LIST_MANUAL:
        return BlockModelFactory.getPageListManualBlock();
      case BlockType.TABLE:
        return BlockModelFactory.getTable();
      case BlockType.EMPLOYEES_AUTO:
        return BlockModelFactory.getEmployeesAuto();
      case BlockType.EMPLOYEES_MANUAL:
        return BlockModelFactory.getEmployeesManual();
      case BlockType.DM_AUTO:
        return BlockModelFactory.getDMAuto();
      case BlockType.DM_MANUAL:
        return BlockModelFactory.getDMManual();
      case BlockType.DM_SINGLE:
        return BlockModelFactory.getDMSingle();
      case BlockType.KP:
        return BlockModelFactory.getKP();
      case BlockType.KP_OWNER:
        return BlockModelFactory.getKPOwner();
      case BlockType.EVENTS_AUTO:
        return BlockModelFactory.getEventsAutoBlock();
      case BlockType.EVENTS_MANUAL:
        return BlockModelFactory.getEventsManualBlock();
      case BlockType.DATELINE:
        return BlockModelFactory.getDatelineBlock();
      case BlockType.FORM:
        return BlockModelFactory.getFormBlock();
      case BlockType.PROGRAM:
        return BlockModelFactory.getProgramBlock();
      case BlockType.MAP_V2:
        return BlockModelFactory.getMapV2Block();
      default:
        // eslint-disable-next-line no-console
        console.warn(`[BlockModelFactory] Concrete factory for block type "${blockType}" not implemented. Using base.`);
        return { ...BlockModelFactory.getBaseBlock(), type: blockType };
    }
  }
}
